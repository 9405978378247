// SHARED

// box-sizing
.fct,
.fwr,
.btn,
.msg,
.wwr {
  box-sizing: border-box;
}


// CONTAINERS

// flex container
.fct {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  //max-width: 100%;
}


// WRAPPERS

// content wrapper
.cwr {
  &>:first-child {
    margin-top: 0;
    * {
      margin-top: 0;
    }
  }
  &>:last-child {
    margin-bottom: 0;
  }
}

// flex wrapper
.fwr {
  min-width: 24rem;
}

// width wrapper
.wwr {
  width: 100%;
  max-width: 108rem;
  margin-left: auto;
  margin-right: auto;
}




// symbols/icons
.sym {
  width: 2rem;
  vertical-align: text-bottom;
  transition: color .25s ease-out, transform .15s ease-in;

  & + * {
    margin-left: .3rem;
  }

}

// button
.btn {
  background-color: $color-dark;
  transition: background-color .2s ease-in-out, color .2s ease-in-out;
  color: white;
  padding: 1.8rem 2.6rem;
  display: inline-block;
  margin: 1.6rem 0;
  border-width: 0;
  cursor: pointer;
  width: auto;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.2rem;
  letter-spacing: .3rem;
  border-radius: 1.8rem 1.4rem 1rem 1.6rem;
  line-height: 1.8rem;
  margin-right:.4rem;
  text-align: center;
  border: .3rem solid $color-text;

  &:active,
  &:focus,
  &.active,
  &:hover {
    background-color: transparent;
    color: $color-text;
  }

  &:active,
  &:focus {
    color: $color-primary;
    border-color: $color-primary;
  }

  .sym {
    display: inline;
    vertical-align: sub;
    margin: 0 1rem 0 -.3rem;
  }

  &.alt {
    background-color: $color-light;
    border: .2rem solid $color-primary;
    color: $color-primary;

    &.active,
    &:hover {
      background-color: $color-primary;
      color: $color-light;
    }

    &:active,
    &:focus {
      color: mix($color-primary,white,60%);
    }
  }

  .c-l & {
    background-color: $color-light;
    color: $color-dark;
    border: .3rem solid $color-light;

    &:active,
    &:focus,
    &.active,
    &:hover {
      background-color: transparent;
      color: $color-light;
    }

    &:active,
    &:focus {
      opacity: .9;
    }

  }
}


// message
.msg {
  padding: 1rem 2rem;
  border-radius: .6rem;
}

// svg
.svg {
  object-fit: contain;
}

.map {
  min-height: 60vh;
}

// separator
.sep {
  width: 100vw;
  height: 6vw;
  top: -3vw;

  & + div {
    padding-bottom: 6vw;
  }

  @media screen and (min-width: $breakpoint-small) {
    margin-bottom: -5vw;
  }
}
