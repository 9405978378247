// base
$base-font-family: 'Mali', cursive;
$base-font-size: 1.4rem;
$base-font-weight: 400;
$base-line-height: 2.8rem;
$base-margin: 2rem;

// colors
$color-primary: #006e52;
$color-secondary: #b65534;
$color-light: white;
$color-dark: #1b2d3f;
$color-text: #1b2d3f;
$color-border: transparent;
$color-focus: lighten(#006e52,5);
$color-highlight: #006e52;
$color-lazyload: mix(white,$color-dark,95%);

// custom colors
$color-brown: #7F462C;
$color-blue: #195174;
$color-green: #347235;
$color-purple: #4E387E;
$color-red: #DC381F;
$color-orange: #b65534;
$color-grey: #504A4B;
$color-black: #2B1B17;
$color-yellow: antiquewhite;
$color-pink: #E67451;

// sizes
$tiny: 1.25rem;
$small: 2rem;
$medium: 3rem;
$large: 4rem;
$huge: 5rem;

// breakpoints
$breakpoint-tiny: 480px;
$breakpoint-small: 800px;
$breakpoint-medium: 1024px;
$breakpoint-large: 1440px;
$breakpoint-huge: 1600px;
