html {
  scroll-behavior: smooth;
}

body {
  animation: overflow ease 0 forwards;
  min-width: 240px;

  &:after {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: repeat asset_url("img/noise.png") center top;
    pointer-events: none;
    z-index: 99;
    box-shadow: 0 0 .4rem rgba(black,.125) inset;
  }
}


a {
  &:hover {
    color: inherit;
  }

  &:focus {
    outline: none;
  }
}

img {
  border-style: none;
  object-fit: contain;
  display: block;
  max-width: 100%;
  min-width: 100%;
  min-height: 100%;
  height: auto;
}

figure {
  overflow: hidden;
}

h1 {
  font-size: 4.2rem;
  line-height: 5.2rem;
  margin: 3rem 0 2rem;
  font-weight: 700;
  letter-spacing: .1rem;
}

h2 {
  font-size: 2.6rem;
  line-height: 3.2rem;
  margin: 3rem 0 2rem;
  letter-spacing: .1rem;
  font-weight: 700;
}


hr {
  margin: 3rem auto;
  width: 14rem;
  border-width: 0;
  height: 1.2rem;
  border-radius: .4rem;
  background-color: mix($color-primary,white,60%);
}

strong {
  font-weight: 600;
}


address {
  font-style: inherit;
}


ul.lst {
  margin-bottom: 2rem;

  li {
    padding-left: 2.4rem;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      border: solid currentColor;
      border-width: 0 .4rem .4rem 0;
      display: inline-block;
      padding: .2rem;
      transform: rotate(-45deg);
      top: 1.2rem;
      left: 0;
    }

    ul {
      margin-bottom: 0;
    }
  }
}

ol.lst {
  list-style: none;
  padding: 0;
  counter-reset: list-counter;

  ol {
    margin-bottom: 0;
  }

  & > li {
    padding-left: 20px;
    position: relative;

    &:before {
      display: inline-block;
      content: counter(list-counter)".";
      counter-increment: list-counter;
      position: absolute;
      left: 0;
      top: 0;
      font-weight: bold;
      font-size: 12px;
    }
  }
}

blockquote {
  border-left: 6px solid $color-primary;
  margin: 3rem 1rem 3rem 0;
  padding: .1rem 1.5rem .3rem;
  line-height: 2.6rem;
  color: grey;
  font-style: italic;

  p {
    margin: 0;
  }
}

figcaption {
  font-style: italic;
  padding: .4rem .8rem;
}

svg {
  max-width: 100%;
  max-height: 100%;
}
