#header {
  padding-bottom: 3vw;
}

#headline {
  margin-top: 15rem;

  @media screen and (min-width: $breakpoint-small) {
    margin-top: $huge;
  }
}

#nav-toggle,
#info {
  height: 6.4rem;
}

#nav-toggle {
  width: 6.4rem;
  transition: border-radius ease-in-out .2s;

  &:hover {
    border-radius: 60% 60% 50% 50%;
    color: mix($color-text, white, 90%);
  }
}

#nav-menu {
  position: fixed;
  transition: opacity .25s ease-in-out;
  background-color: rgba($color-light,.95);
  z-index: -1;
  font-size: 150%;

  &.active {
    top: 0;
    opacity: 1;
  }

  li {
    margin-bottom: 2.5rem;
  }

  a {
    transition: border-color .25s ease-out;
    padding: .2rem 1.6rem;
    border-radius: 1.6rem 1rem 1.2rem 1.2rem;
    border: .3rem solid transparent;

    &.active,
    &:hover {
      color: currentColor;
      border-color: currentColor;
      background-color: white;
    }
  }

}

#nav-icon rect {
  transform-origin: center;
  transform-box: fill-box;
}

.active #ham-t { animation: hamburgerTop .3s ease-in-out forwards; }
.active #ham-m { animation: hamburgerMiddle .15s ease-in-out forwards; }
.active #ham-b { animation: hamburgerBottom .3s ease-in-out forwards; }


#banner {
  max-height: 85vh;
  margin-bottom: -3vw;
}

#logo-small {
  width: 10rem;
  height: 10rem;
}

#footer-logo {
  min-width: 10rem;
}

#footer-credentials {
  color: mix($color-dark,white,50%);
  margin-bottom: -.85rem;
}
