@keyframes slideUp {
  0%      { opacity: 0; transform: translate3d(0,100%,0); }
  100%    { opacity: 1; transform: translate3d(0,0,0); }
}

@keyframes slideDown {
  0%      { opacity: 0; transform: translate3d(0,-100%,0); }
  100%    { opacity: 1; transform: translate3d(0,0,0); }
}

@keyframes rotate {
  0%      { transform: translate3d(0,0,0) rotate(0deg); }
  100%    { transform: translate3d(0,0,0) rotate(360deg); }
}

@keyframes zoomIn {
  25%     { opacity: 0; transform: translate3d(0,0,0) scale(0); }
  85%     { opacity: 1; transform: translate3d(0,0,0) scale(1.05) }
  100%    { opacity: 1; transform: translate3d(0,0,0) scale(1) }
}

@keyframes fadeIn {
  0%      { opacity: 0; }
  100%    { opacity: 1; }
}

@keyframes hamburgerTop {
  70%     { transform: translate3d(0,-8px,0); }
  100%    { transform: translate3d(0,-8px,0) rotate(45deg); }
}

@keyframes hamburgerMiddle {
  100%    { visibility: hidden; }
}

@keyframes hamburgerBottom {
  70%     { transform: translate3d(0,8px,0); }
  100%    { transform: translate3d(0,8px,0) rotate(-45deg); }
}

@keyframes wiggle {
  0%      { transform: rotate(0deg) }
  90%     { transform: rotate(0deg) }
  93%     { transform: rotate(1deg) }
  97%     { transform: rotate(-1deg) }
  100%    { transform: rotate(0deg) }
}

@keyframes overflow {
  0%      { overflow-y: hidden; }
  100%    { overflow-y: visible; }
}
