

.a-fi     {       animation: fadeIn ease .5s forwards; }
.a-su     {       animation: slideUp ease .5s forwards; }
.a-sd     {       animation: slideDown ease .5s forwards; }
.a-zi     {       animation: zoomIn ease .5s forwards; }
.a-wg     {       animation: wiggle ease 3s infinite; }

.adl-25   {       animation-delay: .25s }
.adl-50   {       animation-delay: .50s }
.adl-75   {       animation-delay: .75s }
.adl-100  {       animation-delay: 1s }
.adl-125  {       animation-delay: 1.25s }
.adl-150  {       animation-delay: 1.5s }

.ai-c     {       align-items: center; }
.ai-e     {       align-items: flex-end; }


.b-0      {       bottom: 0; }
.b-c      {       bottom: 50%; transform: translateY(50%); }

.bgc-l    {       background-color: $color-light; }
.bgc-p    {       background-color: $color-primary; }
.bgc-s    {       background-color: $color-secondary; }
.bgc-gr   {       background-color: mix(white,$color-dark,10%); }
.bgc-b    {       background-color: $color-blue }

.br-m     {       border-radius: 20%; }


.bd-m     {       border: .4rem solid currentColor; }
.bd-d     {       border: .2rem dashed currentColor; }

.bs-m     {       box-shadow: .1rem .1rem .6rem rgba($color-dark,.25); }


.c-l      {       color: $color-light; }
.c-d      {       color: $color-dark; }
.c-p      {       color: $color-primary; }
.c-s      {       color: $color-secondary; }
.c-b      {       color: $color-blue; }
.c-ll     {       color: $color-lazyload; }



.d-b      {       display: block; }
.d-f      {       display: flex; }


.fb-30    {       flex-basis: 30%; }
.fb-50    {       flex-basis: 50%; }

.fd-r     {       flex-direction: row; }

.fg-1     {       flex-grow: 1; }

.fs-t     {       font-size: 1.1rem; line-height: 1.8rem; }
.fs-s     {       font-size: 1.3rem; line-height: 2rem; }
.fs-m     {       font-size: 1.5rem; line-height: 2.4rem; }

.fw-7     {       font-weight: 700; }


.jc-e     {       justify-content: flex-end; }
.jc-c     {       justify-content: center; }
.jc-sa    {       justify-content: space-around; }
.jc-sb    {       justify-content: space-between; }


.l-0      {       left: 0; }
.l-c      {       left: 50%; transform: translateX(-50%); }

.ls-m     {       letter-spacing: 0.3rem; }


.obf-c    {       object-fit: cover; }
.o-1      {       opacity: .1; }


.m-t      {       margin: $tiny; }
.m-s      {       margin: $small; }
.m-m      {       margin: $medium; }
.m-l      {       margin: $large; }
.m-h      {       margin: $huge; }

.mb-0     {       margin-bottom: 0; }
.mb-s     {       margin-bottom: $small; }
.mb-m     {       margin-bottom: $medium; }
.mb-l     {       margin-bottom: $large; }
.mb-h     {       margin-bottom: $huge; }

.ml-t     {       margin-left: $tiny; }

.mh-m     {       margin-right: $medium; margin-left: $medium; }

.mt-t     {       margin-top: $tiny; }

.maw-t    {       max-width: $tiny * 10; }
.maw-s    {       max-width: $small * 10; }
.maw-m    {       max-width: $medium * 10; }
.maw-l    {       max-width: $large * 10; }
.maw-h    {       max-width: $huge * 10; }


.p-t      {       padding: $tiny; }
.p-s      {       padding: $small; }
.p-m      {       padding: $medium; }
.p-l      {       padding: $large; }

.pb-0     {       padding-bottom: 0; }
.pb-t     {       padding-bottom: $tiny; }
.pb-h     {       padding-bottom: $huge; }

.pt-0     {       padding-top: 0; }
.pt-m     {       padding-top: $medium; }
.pt-h     {       padding-top: $huge; }

.pv-m     {       padding-top: $medium; padding-bottom: $medium; }
.pv-h     {       padding-top: $huge; padding-bottom: $huge; }

.ps-0     {       top: 0; right: 0; bottom: 0; left: 0}
.ps-a     {       position: absolute; }
.ps-s     {       position: sticky; }
.ps-r     {       position: relative; }


.r-0      {       right: 0; }

.t-0      {       top: 0; }
.t-c      {       top: 50%; transform: translateY(-50%); }

.ta-c     {       text-align: center; }
.ta-r     {       text-align: right; }

.tt-u     {       text-transform: uppercase; }


.w-30     {       width: 30%; }
.w-80     {       width: 80%; }


.z-1      {       z-index: 1; }
.z-2      {       z-index: 2; }
.z-3      {       z-index: 3; }



// states
[class*='_h'] { transition: color .25s ease-in; }
.c-l_h:hover  { color: $color-yellow }
.c-d_h:hover  { color: darken($color-dark,5) }
.c-p_h:hover  { color: darken($color-primary,5) }
.c-s_h:hover  { color: darken($color-secondary,5) }
.c-b_h:hover  { color: darken($color-blue,5) }


// lazyload
.lazyloaded   { animation: fadeIn ease .75s forwards .25s; }

// ratio
.rt-1:before  { padding-bottom: 100%; }
.rt-b:before  { content: ""; display: block; width: 100%; }



// breakpoints
@media screen and (max-width: $breakpoint-tiny) {
  .m-l      { margin: $small; }
  .p-m      { padding: $small; }
  .d-n_T    { display: none; }
}

@media screen and (min-width: $breakpoint-tiny) {
  .T_vh-75  { min-height: 75vh; }
}

@media screen and (min-width: $breakpoint-small) {
  .S_fd-rr  { flex-direction: row-reverse; }
  .S_fd-r   { flex-direction: row; }
  .S_fb-50  { flex-basis: 50%; }
}

@media screen and (min-width: $breakpoint-medium) {
  .M_fb-50  { flex-basis: 50%; }
  .M_fb-30  { flex-basis: 30%; }
  .M_fb-70  { flex-basis: 70%; }
}
